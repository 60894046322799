<script setup lang="ts">
import { useNormalizedItems } from '@/scripts/composables/useNormalizedItems'
import { Option, StringOrNumberPair } from '@/scripts/types'

const props = withDefaults(
    defineProps<{
        name: string
        modelValue?: string | number | null
        items?: Option[] | StringOrNumberPair[] | object
        allowBlank?: boolean
        placeholder?: string
    }>(),
    {
        modelValue: null,
        items: () => [],
        placeholder: '',
    },
)

const emit = defineEmits<{ (e: 'update:modelValue', value: string | number): void }>()

// Normalize items from objects and simple array entries to an array of objects with value and label properties
const normalizedItems = useNormalizedItems(props.items)
</script>

<template>
    <select
        :id="name"
        :name="name"
        :value="modelValue"
        class="form-select block w-full rounded-md border-gray-300 focus-within:z-10 focus:border-pink-500 focus:ring-pink-500 sm:text-sm"
        @change="emit('update:modelValue', ($event.target as HTMLInputElement).value)"
    >
        <option v-if="allowBlank" value="">{{ placeholder }}</option>
        <template v-if="normalizedItems?.length">
            <option v-for="item in normalizedItems" :key="item.value ?? 'empty'" :value="item.value">{{ item.label }}</option>
        </template>
        <slot v-else></slot>
    </select>
</template>
